import index from "../views/Index";
import item_api from "../services/api/item.js";
import welcomeView from "../views/WelcomeView";
import savedScreen from "../views/SavedScreen";
import historyView from "../views/HistoryView";
import login from "../views/LoginWindow";
import HandleRooms from "../views/HandleRooms";
import HandleAddresses from "../views/HandleAddresses";
import HandleLabels from "../views/HandleLabels";
import ArticleInformation from "../views/ArticleInformation";
import NotAuthorized from "../views/NotAuthorized";
import SelectProject from "../views/SelectProject";
import NotFound from "@/shared/views/NotFound";
import { createRouter, createWebHistory } from "vue-router";
import store from "../store";

const routes = [
  {
    name: "saved",
    path: "/saved/:id",
    component: savedScreen,
    meta: {
      requiresProject: true,
      title: `Item`,
    },
    props: true,
  },
  {
    name: "history",
    path: "/history/:id",
    component: historyView,
    meta: {
      requiresProject: true,
      title: `Item`,
    },
    props: true,
  },
  {
    name: "savedNormal",
    path: "/saved",
    component: savedScreen,
    meta: {
      middleware: "guest",
      title: `Item`,
    },
  },
  {
    name: "welcome",
    path: "/",
    alias: "/welcome",
    component: welcomeView,
    meta: {
      middleware: "guest",
      title: `Welcome`,
    },
  },
  {
    name: "login",
    path: "/login",
    component: login,
    meta: {
      middleware: "guest",
      title: `Login`,
    },
  },
  {
    name: "handleRooms",
    path: "/handle-rooms",
    component: HandleRooms,
    meta: {
      requiresProject: true,
      title: `Handle rooms`,
    },
  },
  {
    name: "handleAddresses",
    path: "/handle-addresses",
    component: HandleAddresses,
    meta: {
      requiresProject: true,
      title: `Handle addresses`,
    },
  },
  {
    name: "handleLabels",
    path: "/handle-labels",
    component: HandleLabels,
    meta: {
      requiresProject: true,
      title: `Handle labels`,
    },
  },
  {
    name: "selectProject",
    path: "/select-project",
    component: SelectProject,
    meta: {
      title: `Select project`,
    },
  },

  {
    name: "notAuthorized",
    path: "/not-authorized/:authType?",
    component: NotAuthorized,
    meta: {
      title: `Not authorized`,
    },
  },
  {
    path: "/not-found",
    component: NotFound,
    name: "notFound",
    meta: {
      middleware: "guest",
      title: `404 Not found`,
    },
  },
  {
    name: "Item",
    path: "/:id/:action?",
    component: index,
    meta: {
      title: `Item`,
    },
  },
  {
    name: "articleInformation",
    path: "/:id/information",
    component: ArticleInformation,
    meta: {
      middleware: "guest",
      title: `Article information`,
    },
  },

  {
    path: "/:catchAll(.*)",
    component: NotFound,

    meta: {
      middleware: "guest",
      title: `404 Not found`,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: routes,
  linkActiveClass: "active-link",
});

const verifyItemId = async (id) => {
  return await item_api
    .verifyItemId(id)
    .then(async (response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response.status === 404) {
        return router.push({ name: "notFound" });
      }
    });
};

router.beforeEach((to, from, next) => {
  const userIsAuthenticated = store.state.auth.authenticated;

  if (
    to.meta.middleware === "guest" ||
    userIsAuthenticated ||
    to.name === "Item"
  ) {
    return next();
  }

  return next({ name: "login", query: { redirect: to.fullPath } });
});

router.beforeResolve(async (to, from, next) => {
  const userIsAuthenticated = store.state.auth.authenticated;

  document.title = `${to.meta.title} - ${process.env.VUE_APP_NAME}`;
  if (to.name === "Item") {
    store.dispatch("setIsLoading", true);
    const queryId = to.query?.redirect?.slice(1) ?? to.params.id;
    const item = await verifyItemId(queryId);

    const editRegisteredProduct = to.params.action;

    if (item === 1 && !editRegisteredProduct) {
      return next({
        name: "articleInformation",
        params: { id: queryId },
      });
    }

    if (!userIsAuthenticated)
      return next({ name: "login", query: { redirect: to.fullPath } });

    const currentProjects = store.getters["auth/userProjects"];
    if (!currentProjects || !currentProjects.length)
      return next({
        name: "notAuthorized",
        params: {
          authType: "P",
        },
      });

    return next();
  }

  if (
    (!store.getters.project_settings ||
      store.getters.project_settings?.active === "0") &&
    to.meta.requiresProject === true
  ) {
    return next({ name: "selectProject" });
  }

  return next();
});
router.afterEach(() => {
  store.dispatch("setIsLoading", false);
});
export default router;
