import axios from "axios";
import item from "../services/api/item";

export default {
  namespaced: true,
  state: {
    authenticated: false,
    user: {},
    userProjects: null,
  },
  getters: {
    authenticated(state) {
      return state.authenticated;
    },
    user(state) {
      return state.user;
    },
    userProjects(state) {
      return state.userProjects;
    },
  },
  mutations: {
    SET_AUTHENTICATED(state, value) {
      state.authenticated = value;
    },
    SET_USER(state, value) {
      state.user = value;
    },
    SET_USER_PROJECTS(state, value) {
      state.userProjects = value;
    },
  },
  actions: {
    async getProjects({ commit }) {
      await item
        .getProjects()
        .then(async (response) => {
          const missingActiveProjects = !response.data.find(
            (project) => project.active === "1"
          );
          const projects = missingActiveProjects ? null : response.data;

          commit("SET_USER_PROJECTS", projects);

          return "OK";
        })
        .catch(() => {
          return "error with getting projects";
        });
    },
    async login({ commit, dispatch }) {
      const token = localStorage.getItem("token");

      if (token) {
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
      }

      return axios
        .get("api/user")
        .then(async ({ data }) => {
          commit("SET_USER", data);
          await dispatch("getProjects");

          commit("SET_AUTHENTICATED", true);

          return "OK";
        })
        .catch(() => {
          commit("SET_USER", {});
          commit("SET_AUTHENTICATED", false);
          return "ERROR";
        });
    },
    logout({ commit }) {
      commit("SET_USER", {});
      commit("SET_AUTHENTICATED", false);
      commit("SET_USER_PROJECTS", null);
    },
  },
};
