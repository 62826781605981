<template>
  <div id="app">
    <NavBar
      :sideBar="sideBar"
      :isLoading="isLoading"
      :desktop="desktop"
      :companyLogo="null"
      ref="navbar"
      :key="isAuthenticated"
    >
      <template #pageContent>
        <div class="d-flex flex-column align-items-center w-100 layout-margin">
          <div class="main-layout position-relative">
            <LoadingOverlay v-if="$store.getters.isLoading" />
            <router-view :key="$route.fullPath" />
          </div>
        </div>
      </template>
    </NavBar>
  </div>
</template>

<script>
import NavBar from "./shared/navbar/NavBar.vue";
import LoadingOverlay from "./shared/components/LoadingOverlay.vue";
import router from "./item/router";
import { mapActions } from "vuex";
import axios from "axios";
import navigation from "./shared/navigation.json";

export default {
  name: "App",
  components: { NavBar, LoadingOverlay },
  data() {
    return {
      isLoading: false,
      desktop: {
        left: [
          {
            requires: null,
            label: "menu.home",
            route: "welcome",
            navigate: (route) => this.navigateTo(route),
            dropdown: null,
          },
          {
            requires: null,
            label: "button.scanNewProduct",
            route: "savedNormal",
            navigate: (route) => this.navigateTo(route),
            dropdown: null,
          },
          {
            requires: ["A"],
            label: "menu.settings",
            route: null,
            navigate: (route) => this.navigateTo(route),
            dropdown: {
              updated: null,
              value: this.$route.name,
              reduced: "route",
              dropdownLabel: "label",
              placeholder: "menu.settings",
              options: navigation.subNavigations,
            },
          },
        ],
        right: [
          {
            label: "message.project",
            requires: ["A", "P"],
            dropdown: {
              updated: (project) => this.selectProject(project),
              value: this.$store.getters.project_settings?.projekt,
              reduced: "projektnr",
              dropdownLabel: "projektbenamn",
              placeholder: "message.project",
              options: this.$store.getters["auth/userProjects"]?.filter(
                (project) => project.active === "1"
              ),
            },
          },
          {
            label: "menu.language",
            requires: null,
            dropdown: {
              updated: (language) => this.selectLanguage(language),
              value: this.$i18n.locale,
              reduced: "iso",
              dropdownLabel: "label",
              placeholder: null,
              options: navigation.language,
            },
          },
        ],
        end: {
          requires: ["A"],
          signOut: "menu.signOut",
          signIn: "button.signIn",
          logIn: () => this.login(),
          logOut: () => this.logout(),
        },
      },
      sideBar: {
        top: [
          {
            label: "message.project",
            requires: ["A", "P"],
            action: {
              updated: (project) => this.selectProject(project),
              value: this.$store.getters.currentProject,
              reduced: "projektnr",
              dropdownLabel: "projektbenamn",
              placeholder: "message.project",
              options: this.$store.getters["auth/userProjects"]?.filter(
                (project) => project.active === "1"
              ),
            },
            fallbackValue: "label.noActiveProjects",
          },
          {
            label: "menu.language",
            requires: null,
            action: {
              updated: (language) => this.selectLanguage(language),
              value: this.$store.getters.language ?? "en",
              reduced: "iso",
              dropdownLabel: "label",
              placeholder: null,
              options: navigation.language,
            },
            fallbackValue: null,
          },
        ],
        mid: [
          {
            requires: null,
            label: "menu.home",
            route: "welcome",
            navigate: (route) => this.navigateTo(route),
            subNavigations: null,
          },
          {
            requires: ["A"],
            label: "menu.settings",
            route: null,
            navigate: (route) => this.navigateTo(route),
            subNavigations: navigation.subNavigations,
          },
          {
            requires: null,
            label: "button.scanNewProduct",
            route: "savedNormal",
            navigate: (route) => this.navigateTo(route),
            subNavigations: null,
          },
        ],
        bottom: {
          requires: ["A"],
          signOut: "menu.signOut",
          signIn: "button.signIn",
          logIn: () => this.login(),
          logOut: () => this.logout(),
        },
      },
    };
  },
  methods: {
    ...mapActions({
      signOut: "auth/logout",
      changeLanguage: "changeLanguage",
      setProjectSettings: "setProjectSettings",
    }),
    async logout() {
      await axios.post("/api/logout").then(() => {
        this.signOut();
        this.$router.push({ name: "login" });
        if (this.$refs?.navbar?.$refs.sideBar) {
          this.$refs?.navbar?.$refs.sideBar.closeMenu();
        }
      });
    },
    navigateTo(route) {
      router.push({ name: route });
      if (this.$refs?.navbar?.$refs.sideBar) {
        this.$refs?.navbar?.$refs.sideBar.closeMenu();
      }
    },
    login() {
      router.push({ name: "login", query: { redirect: this.$route.fullPath } });
      if (this.$refs?.navbar?.$refs.sideBar) {
        this.$refs?.navbar?.$refs.sideBar.closeMenu();
      }
    },

    selectLanguage(lang) {
      this.$i18n.locale = lang;
      this.changeLanguage(lang);
    },
    selectProject(project) {
      if (project !== this.$store.getters.currentProject) {
        this.isLoading = true;
        this.setProjectSettings(project)
          .then(async () => {
            await router.push({ name: "welcome" });
            this.isLoading = false;
          })
          .catch((e) => {
            this.$httpError(e, "App - setProjectSettings");
            this.isLoading = false;
          });
      }
    },
  },
  computed: {
    userProjects() {
      return this.$store.getters["auth/userProjects"];
    },
    isAuthenticated() {
      return this.$store.getters["auth/authenticated"];
    },
    projectNr() {
      return this.$store.getters.currentProject;
    },
    lang() {
      return (
        this.$store.getters.project_settings?.sprak ??
        navigator.language.slice(0, 2).toLocaleLowerCase() ??
        "en"
      );
    },
    currentStoreLanguage() {
      return this.$store.getters.language;
    },
  },

  mounted() {
    if (!this.$store.getters.language) {
      this.$i18n.locale = this.lang;
    }
  },
  watch: {
    projectNr(newProject) {
      this.sideBar.top[0].action.value = newProject;
      this.desktop.right[0].dropdown.value = newProject;
      if (!this.$store.getters.language) {
        this.$i18n.locale = this.lang;
      }
    },

    isAuthenticated(auth) {
      if (auth) {
        this.desktop.right[0].dropdown.options =
          this.$store.getters["auth/userProjects"];
        this.sideBar.top[0].action.options =
          this.$store.getters["auth/userProjects"];
      }
    },
    currentStoreLanguage(newLanguage) {
      this.sideBar.top[1].action.value = newLanguage;
      this.desktop.right[1].dropdown.value = newLanguage;
    },
    userProjects(newProjects) {
      if (!newProjects) return;
      const currProjects = newProjects.filter(
        (project) => project.active === "1"
      );
      this.sideBar.top[0].action.options = currProjects;
      this.desktop.right[0].dropdown.options = currProjects;
    },
  },
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}

#app {
  display: flex;

  flex-direction: column;
  justify-content: center;
}

.layout-margin {
  margin-top: 96px;
}
</style>
