<template>
  <LoadingOverlay v-if="processing" />
  <GreenifiedCard :cardTitle="$t('button.signIn')">
    <template #cardContent>
      <form action="javascript:void(0)" method="post" class="mt-4">
        <div class="row mt-1">
          <div class="col-12 col-sm-6 col-lg-4">
            <InputField
              :label="$t('placeholders.emailAdress')"
              id="email"
              v-model="auth.name"
              name="email"
            />
          </div>
        </div>

        <div class="row mt-4">
          <div class="col-12 col-sm-6 col-lg-4">
            <InputField
              :label="$t('placeholders.password')"
              id="password"
              type="password"
              :passwordHasToggle="true"
              v-model="auth.password"
              name="password"
            />
          </div>
        </div>

        <div class="row mt-2rem">
          <div class="col" @click="showReset = true">
            <p class="cap-medium cursor-pointer">
              {{ $t("message.forgotYourPassword") }}
            </p>
          </div>
        </div>
        <div class="row mt-2rem">
          <div class="col-12 col-sm-6 col-md-3">
            <GreenifiedButton
              btnType="submit"
              noWrap
              @clicked="login"
              type="black"
              :disabled="processing"
              :text="processing ? $t('button.pleaseWait') : $t('button.signIn')"
            />
          </div>
        </div>
        <div class="row mt-4">
          <div class="col">
            <p class="cap-medium cursor-pointer">
              {{ $t("message.haveNoAccount") }}
            </p>
          </div>
        </div>
      </form>
      <VerificationModal
        v-if="showError"
        @close="
          showError = false;
          processing = false;
        "
        :confirm-button-text="$t('button.close')"
        :single-button="true"
        :title="$t('verificationTitles.loginError')"
        :text="$t('errorMessage.loginError')"
      />
      <ResetPassword
        v-if="showReset"
        @close="showReset = false"
        :resetPasswordApi="itemApi.resetPassword"
      />
    </template>
  </GreenifiedCard>
</template>

<script>
import { mapActions } from "vuex";
import axios from "axios";
import GreenifiedButton from "@/shared/components/GreenifiedButton.vue";
import GreenifiedCard from "@/shared/components/GreenifiedCard.vue";
import InputField from "@/shared/components/InputField.vue";
import VerificationModal from "@/shared/modals/VerificationModal.vue";
import ResetPassword from "@/shared/components/ResetPassword.vue";
import item from "../services/api/item";
import router from "../router";
import LoadingOverlay from "@/shared/components/LoadingOverlay.vue";
export default {
  name: "LoginWindow",
  data() {
    return {
      itemApi: item,
      auth: {
        name: "",
        password: "",
      },
      showReset: false,
      processing: false,
      showError: false,
    };
  },

  methods: {
    ...mapActions({
      signIn: "auth/login",
    }),
    async login() {
      this.processing = true;
      await axios.get("/sanctum/csrf-cookie").catch(() => {
        return (this.showError = true);
      });
      await axios
        .post("/api/login", this.auth)
        .then(async ({ data }) => {
          localStorage.setItem("token", data.access_token);
          const setUser = await this.signIn();

          if (setUser !== "OK") {
            return (this.showError = true);
          } else {
            await router.push({
              path: this.$route.query.redirect ?? "/welcome",
            });
            this.processing = false;
          }
        })
        .catch(() => {
          this.showError = true;
        });
    },
  },

  components: {
    GreenifiedButton,
    GreenifiedCard,
    InputField,
    VerificationModal,
    ResetPassword,
    LoadingOverlay,
  },
};
</script>

<style scoped>
.inp-login-card {
  background: #f7f5ee;
  padding: 1rem 2rem 2rem 2rem;
}
.login-btn-container {
  text-align: center;
  padding-top: 1rem;
}
</style>
